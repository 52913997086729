import { SelectValue } from "@appkit4/react-components/esm/combobox/Combobox";
import { ListType } from "types/analysis";
import { MatchData, MatchingData, MatchTable, ProfileData, TestData, UserMatchData } from "types/user";

const getDate = (date: string, outside: boolean = false) => {
  if (outside){
    const separator = date.includes("/") ? "/" : ".";
    if(separator === "/"){
      const [month, day, year] = date.split("/");
      return new Date(parseInt(year), parseInt(month) - 1, parseInt(day)).toLocaleDateString(window.navigator.language);
    } else {
      const [day, month, year] = date.split(".");
      return new Date(parseInt(year), parseInt(month) - 1, parseInt(day)).toLocaleDateString(window.navigator.language);
    }
  }
  else
  return new Date(Date.parse(date)).toLocaleDateString(window.navigator.language);
}
const getApi = () => process.env.REACT_APP_API_URI || ""; 
const parseParams = (values: object) => Object.entries(values)
  .map(([k, v]) => v !== undefined ? `${k}=${v}` : null)
  .filter(f => f !== null)
  .join("&");

const lockStatuses = [32, 64, 96, 128, 160, 192, 224];

const parseLockStatus = (lockStatus: number) => {
  switch (lockStatus) {
    case 32:
      return "Locked by CUA central administrator";
    case 64:
      return "Locked by administrator";
    case 96:
      return "Locked by CUA central administrator + Locked after failed logon";
    case 128:
      return "Locked after failed logon";
    case 160:
      return "Locked by CUA central administrator + Locked after failed logon";
    case 192:
      return "Locked by administrator + Locked after failed logon";
    case 224:
      return "Locked by Administrator";
    default:
      return "";
  }
}

const parseTestFilter = (testFilter: number[]) => testFilter.reduce((a, b) => a + b, 0);

const getTest = (tests: TestData[], functionId: string) => {
  for (let test of tests) {
    for (let func of test.functions)
      if (func.functionId === functionId)
        return test;
  }
  return null;
}

const parseMatches = (matchData: UserMatchData, userData: MatchingData, type: ListType, typeId: string, filter: boolean = true) => {
  let tempData: MatchTable[] = [];
  for (let transaction of matchData.data) {
    let profileIds = transaction.matches.map(m => m.profileId);
    // let tempFunc = type === "test" || type === "all"
    //   ? test?.functions.find(f => f.functionId === transaction.functionId)?.identifier || null
    //   : type === "transaction"
    //     ? userData.tests.find(f => f.functions.map(m => m.functionId).includes(transaction.functionId))?.identifier || null
    //     : null;
    let test = getTest(userData.tests, transaction.functionId);

    let tempFunc = test?.functions.find(f => f.functionId === transaction.functionId)?.identifier;

    if(!tempFunc) continue;
    // if (test) //  || ["role", "compositeRole"].includes(type)
    // {
    if (type !== ListType.Role)
      for (let compositeRole of userData.roles.filter(f => type === ListType.CompositeRole ? (f.isComposite && f.roleId === typeId) : f.isComposite)) {
        if (compositeRole.roles)
          for (let role of compositeRole.roles)
            if (role.profiles)
              for (let profile of role.profiles.filter(m => profileIds.includes(m.profileId))) {
                let matches = transaction.matches.filter(f => f.profileId === profile.profileId);
                if (matches)
                  for (let match of matches) {
                    tempData.push({
                      test: test?.identifier,
                      function: tempFunc,
                      profile: profile.name,
                      compositeRole: compositeRole.name,
                      role: role.name,
                      transaction: transaction.identifier,
                      authorisation: match.name,
                      object: match.object,
                      authFrom: match.authFrom,
                      authTo: match.authTo,
                      field: match.field,
                      testedValues: match.testedValues,
                    });
                  }
              }
      }
      for (let compositeProfile of userData.profiles.filter(f => f.isComposite)) {
        if (compositeProfile.profiles)
          for (let profile of compositeProfile.profiles.filter(m => profileIds.includes(m.profileId))) {
            let matches = transaction.matches.filter(f => f.profileId === profile.profileId);
            if (matches)
              for (let match of matches) {
                tempData.push({
                  test: test?.identifier,
                  function: tempFunc,
                  profile: profile.name,
                  compositeProfile: compositeProfile.name,
                  transaction: transaction.identifier,
                  authorisation: match.name,
                  object: match.object,
                  authFrom: match.authFrom,
                  authTo: match.authTo,
                  field: match.field,
                  testedValues: match.testedValues,
                });
              }
          }
      }
    if (type !== ListType.CompositeRole)
      for (let role of userData.roles.filter(f => type === ListType.Role ? (!f.isComposite && f.roleId === typeId) : !f.isComposite)) {
        if (role.profiles)
          for (let profile of role.profiles.filter(m => profileIds.includes(m.profileId))) {
            let matches = transaction.matches.filter(f => f.profileId === profile.profileId);
            if (matches)
              for (let match of matches)
                if (filter || filterDuplicates(tempData, match, profile)) {
                  tempData.push({
                    test: test?.identifier,
                    function: tempFunc,
                    profile: profile.name,
                    role: role.name,
                    transaction: transaction.identifier,
                    authorisation: match.name,
                    object: match.object,
                    authFrom: match.authFrom,
                    authTo: match.authTo,
                    field: match.field,
                    testedValues: match.testedValues,
                  });
                }
          }
      }

    for (let profile of userData.profiles.filter(f => !f.isComposite)) {
      let matches = transaction.matches.filter(f => f.profileId === profile.profileId);
      if (matches)
        for (let match of matches)
          if (filter || filterDuplicates(tempData, match, profile)) {
            tempData.push({
              test: test?.identifier,
              function: tempFunc,
              profile: profile.name,
              transaction: transaction.identifier,
              authorisation: match.name,
              object: match.object,
              authFrom: match.authFrom,
              authTo: match.authTo,
              field: match.field,
              testedValues: match.testedValues,
            });
          }

    }
  }
  // }

  return tempData;
}

const filterDuplicates = (tempData: MatchTable[], match: MatchData, profile: ProfileData) =>
  tempData.filter(f => f.object === match.object && f.profile === profile.name && f.authorisation === match.name && f.testedValues === match.testedValues).length === 0;

const isAutoScroll = () => {
  let autoScroll = localStorage.getItem("autoScroll") === "true";
  return autoScroll;
}

/**
 * Checks the values of an array against a list of fields.
 * @param array - The array to check.
 * @param fields - The list of fields to check against.
 * @returns An array of fields that have errors, or `true` if all fields are valid, or `false` if the array is empty.
 */
const checkFormValues = <T>(array: T, fields: Array<keyof T>) => {
  let errors: Array<keyof T> = [];
  if (array && Object.keys(array).length > 0)
    {
      for (let x of fields)
        switch (typeof array[x]) {
          case "number":
            if(array[x] === undefined && typeof array[x] === "number")
              errors.push(x);
            break;
          default:
            if(array[x] === undefined || (array[x] as string).trim().length === 0)
              errors.push(x);
            break;
        }
      if(errors.length > 0)
        return errors;
      else
        return [];
    }
  else {
    return fields;
  }
}

/**
 * Filters the data based on the search string and specified keys.
 * 
 * @template T - The type of the data being filtered.
 * @param {T} data - The data to be filtered.
 * @param {Array<keyof T>} keys - The keys to be searched in the data.
 * @param {string} [search] - The search string.
 * @returns {boolean} - Returns true if the data matches the search criteria, otherwise false.
 */
const searchFilter = <T>(data: T, keys: Array<keyof T>, search?: string) => {
  if (!search || search.length < 2) return true;
  if (typeof data === "object" && data)
    for (let key of keys)
      if ((data[key] as string) !== undefined && (data[key] as string !== null) && (data[key] as string).toLowerCase().includes(search.toLowerCase()))
        return true;

  return false;
}

const selectFilter = <T>(data: T, key: keyof T, select?: SelectValue) => {
  if (Array.isArray(select)) {
    if (select.length === 0) return true;
    if(Array.isArray(data[key])) 
      return (data[key] as string[]).some(s => select.includes(s)) 
    else 
      return select.includes(data[key] as string);
  }
  return true;
}

const paginate = <T>(data: T, currentPage: number, pageOffset: number) => {
  if (Array.isArray(data))
    return data.slice((currentPage - 1) * pageOffset, currentPage * pageOffset)
  else
    return data;
}

const objToParams = <T>(data: T) => {
  if (typeof data === "object" && data)
    return Object.keys(data).map(key => data[key as keyof T] ? key + '=' + data[key as keyof T] : "").filter(f => f.length !== 0).join('&');
  else
    return ""
}

const updateParams = (key: string, value: SelectValue, searchParams: URLSearchParams) => {
  if (!value)
    searchParams.delete(key);
  else if (Array.isArray(value)) {
    if (value.length === 0)
      searchParams.delete(key);
    else
      searchParams.set(key, value.join(":"));
  }
  else {
    searchParams.set(key, value.toString());
  }
  searchParams.set("p", "1");
  return searchParams
}

export {
  getDate,
  getApi,
  parseParams,
  checkFormValues,
  objToParams,
  paginate,
  parseTestFilter,
  parseMatches,
  parseLockStatus,
  searchFilter,
  selectFilter,
  updateParams,
  isAutoScroll,
  lockStatuses
}