import { Panel } from "@appkit4/react-components";
import Loader from "components/common/loader";
import ToolTip from "components/common/tooltip";
import { useAtomValue } from "jotai";
import { filterAtom } from "jotai/store";
import { FetchAnalysisData, FetchAnalysisList } from "queries/hooks/analysis/analysis";
import { FetchBusinessProcessGraph } from "queries/hooks/analysis/graphs";
import { FC, useCallback, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Bar, BarChart, Label, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { getDate } from "services/common";
import { shadeRainbow, themeColours } from "style/theme";

type BarData = {
  process: string;
  current: number;
  comparison: number;
  name: string;
}

const ByBusinessGraph: FC = () => {
  const { analysisId } = useParams();
  const [searchParams] = useSearchParams();

  const [comparisonId] = useState(searchParams.get("comparisonAnalysisId") || undefined);
  const comparisonAnalysisId = useCallback(() => searchParams.get("comparisonAnalysisId") || undefined, [searchParams]);

  const userFilter = useAtomValue(filterAtom);

  const { data: analysisData } = FetchAnalysisData(analysisId);

  const { data: analysisList } = FetchAnalysisList(analysisData?.client?.clientId);

  const { data: processList, isPending } = FetchBusinessProcessGraph(analysisId, userFilter, analysisList, comparisonAnalysisId());

  const getComparisonDate = () => {
    if (!analysisList) return;
    let currentIndex = analysisList.findIndex(f => f.analysisId === analysisId);
    if (!comparisonId) {
      let id = analysisList.length === 1
        ? undefined : currentIndex === analysisList.length - 1
          ? analysisList[currentIndex - 1].analysisId
          : analysisList[currentIndex + 1].analysisId;
      return getDate(analysisList.find(f => f.analysisId === id)?.extractionDate || "");
    }
  }

  const getGraphData = () => {
    let result: BarData[] = [];
    if (!processList) return result;
    let bps = processList?.map(m => m.abbreviation);
    if (bps)
      for (let bp of bps) {
        let current = processList?.find(f => f.abbreviation === bp)?.userCount || 0;
        let comparison = processList?.find(f => f.abbreviation === bp)?.comparisonUserCount || 0;
        result.push({ process: bp, current: current, comparison: comparison, name: processList?.find(f => f.abbreviation === bp)?.name || "" });
      }
    return result;
  }
  return (
    <Panel
      title="Test match comparison by business process"
      // className="ap-panel-graph"
      extra={(
        <ToolTip content={() => (
          <>
            <h3>How to view</h3>
            <p style={{ margin: "8px 0" }}>Shows the number of test matches by business process in the current analysis and in the comparison analysis.</p>
            <p style={{ margin: "8px 0" }}>If the current analysis is the most recent one, the comparison analysis will be the previous analysis and vice versa.</p>
            <p style={{ margin: "8px 0" }}>On the "By business process" view the comparison analysis can be manually selected.</p>
          </>
        )} position="left">
          <span className="Appkit4-icon icon-information-fill ap-font-16 ap-panel-information"></span>
        </ToolTip>
      )}
    >
      {isPending
        ? <Loader loadingType="circular" inline />
        : (
          <ResponsiveContainer width="100%" height={350}>
            <BarChart
              data={getGraphData()}
              style={{ margin: 16 }}
            >
              <XAxis dataKey="process" />
              <YAxis />
              <Tooltip
                content={(e) => {
                  if (e.payload && e.payload.length) {
                    return (
                      <Panel>
                        <p><span className="ap-font-medium">{e.payload[0].payload.name}</span></p>
                        <div className="grid gap-4">
                          <div>
                            <p className="ap-font-medium">Current analysis</p>
                            <span className="ap-font-large">{e.payload[0]?.payload.current.toLocaleString()}</span> matches
                          </div>
                          <div>
                            <p className="ap-font-medium">Comparison analysis</p>
                            <span className="ap-font-large">{e.payload[0]?.payload.comparison.toLocaleString()}</span> matches
                          </div>
                        </div>
                      </Panel>
                    )
                  }
                  else
                    return null;
                }}
              />
              <Legend
                content={(props) => {
                  const { payload } = props;
                  if (!payload) return null;
                  return (
                    <ul className="ap-legend">
                      {payload.map((entry, index) => (
                        <li key={`item-${index}`} className="ap-legend-item">
                          <span className="ap-legend-icon" style={{ backgroundColor: entry.color }} />
                          <span className="ap-legend-label">{entry.value}</span>
                        </li>
                      ))}
                    </ul>
                  );
                }}
              />
              <Label content={(props) => {
                return (
                  <p>value</p>
                )
              }} position="top" />
              <Bar name={getDate(analysisList?.find(f => f.analysisId === analysisId)?.extractionDate || "")} dataKey="current" fill={themeColours.ORANGE}>
                <LabelList dataKey="current" position="top" content={(props) => {
                  let posX = props.x ? props.x as number : 0;
                  let posY = props.y ? props.y as number : 0;
                  return (
                    <>
                    {/* <rect x={posX-9} y={posY-21} width={40} height={20} fill="#ffffff" rx={5} ry={5} /> */}
                      <text x={posX + 12} y={posY - 8} width={40} height={20} textAnchor="middle" fill="#474747" fontSize={8} className="ap-bar-label">{props.value?.toLocaleString()}</text>
                    </>
                  )
                }} />
              </Bar>
              <Bar name={getComparisonDate()} dataKey="comparison" fill={shadeRainbow[7]}>
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        )
      }
    </Panel>
  )
}

export default ByBusinessGraph;