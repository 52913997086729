import { FC, useCallback, useEffect, useState } from "react";
import { Button, Search, Switch, Tab, Tabs } from "@appkit4/react-components";

import { ListType, Role } from "types/analysis";
import Loader from "components/common/loader";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Paginate from "components/common/paginate";
import { Chevron, RoleItem, TechnicalRoles, TestDetails, TestIcon } from "components/common/helpers";
import UserMatches from "../user/userMatches";
import { TestData } from "types/user";
import { ActiveFilters } from "components/layout/filters";
import { useAtomValue } from "jotai";
import { filterAtom } from "jotai/store";
import { updateParams } from "services/common";
import { FetchRoles, FetchRoleTests } from "queries/hooks/analysis/role";

const RoleList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { analysisId, roleId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get("search") || "");
  const [filterComposite, setFilterComposite] = useState(searchParams.get("composite") === "1" ? true : false);
  const [filterUsers, setFilterUsers] = useState(searchParams.get("attached") === "1" ? true : false);

  const getAttached = useCallback(() => searchParams.get("attached") === "1" ? true : false, [searchParams]);
  const getComposite = useCallback(() => searchParams.get("composite") === "1" ? true : false, [searchParams]);
  const getCurrentPage = useCallback(() => parseInt(searchParams.get("p") || "1"), [searchParams]);
  const getOffset = useCallback(() => parseInt(searchParams.get("o") || "50"), [searchParams]);

  const isRoleMatch = useCallback((id: string) => {
    return id === roleId
  }, [roleId]);

  const userFilter = useAtomValue(filterAtom);

  const { isPending, data, isFetching, error } = FetchRoles(analysisId, userFilter, (getCurrentPage() * 50) - 50, getAttached(), getComposite());

  return (
    <div id="list-top">
      <div className="flex items-center gap-4 mt-4">
        <div>
          <Search
            searchType={"secondary"}
            onChange={(value: string) => {
              setSearch(value);
            }}
            searchValue={search}
            className="list-filter"
          />
        </div>
        <div>
          <div>
            <Switch onChange={(value: boolean) => {
              setFilterUsers(value);
            }} checked={filterUsers} showIndicator>Roles with users only</Switch>
          </div>
        </div>
        <div>
          <div>
            <Switch onChange={(value: boolean) => {
              setFilterComposite(value);
            }} checked={filterComposite} showIndicator>Composite roles</Switch>
          </div>
        </div>
        <div>
          {(search || getAttached()) && <Button onClick={() => {
            setSearch("");
            setSearchParams(updateParams("attached", [], searchParams));
            setSearchParams(updateParams("search", "", searchParams));
          }} kind="secondary" style={{ display: "block" }}>Clear</Button>}
        </div>
        <div>
          <Button onClick={async () => {
            setSearchParams(updateParams("search", search, searchParams));
            setSearchParams(updateParams("attached", filterUsers ? 1 : 0, searchParams));
            setSearchParams(updateParams("composite", filterComposite ? 1 : 0, searchParams));
          }}>Apply</Button>
        </div>
      </div>
      {(!isPending && data) && <ActiveFilters rows={data?.pagination.totalItems}></ActiveFilters>}
      <div
        className="ap-list-header ap-business-process-header flex gap-4 p-4 pt-6 pb-6 items-center w-full"
      >
        <div className="basis-12"></div>
        <div className="basis-80">
          Role
        </div>
        <div className="basis-32">
          Role type
        </div>
        <div className="basis-28">
          One or more matches
        </div>
        <div className="basis-52">
          Users out of total
        </div>
        <div>
          Uses with the role
        </div>
      </div>
      {
        isPending || isFetching || error
          ? <Loader inline loadingType="circular" error={error?.message} />
          : <div className="list-roles">
            {
              data?.roles.map((role) => (
                <div className="list-row" key={role.name} id={role.name}>
                  <RoleItem key={role.roleId} role={role} open={isRoleMatch(role.roleId)} onClick={() => {
                    if (isRoleMatch(role.roleId)) {
                      navigate(`/analysis/${analysisId}/roles/${location.search}`);
                    } else {
                      if (role.hasMatches || (role.hasMatches && role.isComposite)) {
                        navigate(`/analysis/${analysisId}/roles/${role.roleId}${location.search}#${role.name}`)
                      }
                    }
                  }} />
                  {
                    isRoleMatch(role.roleId) && <RoleTestList role={role} />
                  }
                </div>
              ))}
          </div>
      }
      {data && (<Paginate
        noPageSet
        getTotalPages={Math.ceil(data?.pagination.totalItems / getOffset())}
      />)}
    </div>
  );
}

const RoleTestList: FC<{ role: Role }> = ({ role }) => {
  const { analysisId } = useParams();
  const businessProcesses = ["FIN", "HR", "IT", "MMI", "O2C", "P2P", "PB", "SB"];

  const [selectedTest, setSelectedTest] = useState<string>();
  const [activeIndex, setActiveIndex] = useState<number>(8);

  const userFilter = useAtomValue(filterAtom);

  const { data, isPending, error } = FetchRoleTests(analysisId, role.roleId, userFilter, role.isComposite);

  const getBPLabel = (id: string) => <span>{id} <span className="ap-item-count">{getTestCount(id)}</span></span>

  const [subIndex, setSubIndex] = useState(0);

  const getProcessWithTests = useCallback(() => {
    if (!data) return 8;
    for (let i = 0; i < businessProcesses.length; i++) {
      if (getTestCount(businessProcesses[i]) > 0) return i;
    }
    return 8;
  }, [data, businessProcesses]);

  function getTestCount(id: string) {
    return (data?.filter(test => test.businessProcess.split(":")[0] === id) || []).length || 0;
  }

  useEffect(() => {
    if (data && !isPending) {
      setActiveIndex(getProcessWithTests());
      document.getElementById("process")?.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [data, isPending]);

  return (isPending || error
    ? <Loader loadingType="circular" inline error={error?.message} />
    : <div className="accordion-wrapper ap-accordion-open" id="process" style={{ scrollMarginTop: 80 }}>
      <div className="tabs-wrapper">
        <Tabs activeIndex={activeIndex} onTabChange={setActiveIndex}>
          {businessProcesses.map((bp, index) => <Tab label={getBPLabel(bp)} value={index.toString()} disabled={getTestCount(bp) === 0} key={bp}></Tab>)}
          <Tab label="Technical roles" value="8" disabled={!role.isComposite}></Tab>
        </Tabs>
      </div>
      {
        activeIndex < 8
          ? (
            <>
              <div className="ap-list-header ap-business-process-test-header flex gap-4 p-4 pt-6 pb-6 items-center">
                <div className="basis-12">
                </div>
                <div className="basis-96">
                  Test
                </div>
                <div>
                  Test type
                </div>
              </div>
              {(data?.filter(test => test.businessProcess.split(":")[0] === businessProcesses[activeIndex]) || []).sort((a, b) => a.identifier.localeCompare(b.identifier)).map(test => (
                <div className={`list-row-1`} key={test.testId}>
                  <div className="flex items-center p-4 gap-4">
                    <div className="basis-12">
                      <Chevron open={test.testId === selectedTest} onClick={() => {
                        setSubIndex(0);
                        test.testId === selectedTest
                          ? setSelectedTest(undefined)
                          : setSelectedTest(test.testId);
                      }} />
                    </div>
                    <div className="basis-96">
                      <b>{test.identifier}</b>
                      <p>{test.functions?.map(m => <span className="ap-function-description">{m.description}</span>)}</p>
                    </div>
                    <div>
                      <TestIcon type={test.type} />
                    </div>
                  </div>
                  {
                    test.testId === selectedTest && (
                      <>
                        <div className="tabs-wrapper">
                          <Tabs activeIndex={subIndex} onTabChange={setSubIndex}>
                            <Tab label="Matches" value="0"></Tab>
                            <Tab label="Test details" value="1"></Tab>
                          </Tabs>
                        </div>
                        {subIndex === 0
                          ? (
                            <RoleMatches role={role} test={test} />
                          )
                          : (
                            <TestDetails test={test} />
                          )
                        }
                      </>
                    )
                  }
                </div>
              ))}
            </>
          )
          : <TechnicalRoles roles={role.roles || []} />
      }
    </div>
  )
}

const RoleMatches: FC<{ role: Role, test: TestData }> = ({ role, test }) => {
  return (
    <UserMatches
      type={role.isComposite ? ListType.CompositeRole : ListType.Role}
      typeId={role.roleId}
      matchingData={{ roles: [role], tests: [test], profiles: [] }} />
  )
}

export default RoleList;
