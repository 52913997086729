import { FC, ReactNode } from "react"
import ToolTip from "./tooltip"
import { themeColours } from "style/theme"
import styled from "@emotion/styled"

const PercentageWrapper = styled.div<{ dark: boolean | undefined, children: ReactNode }>`
  width: 100%;
  background-color: ${props => props.dark ? "rgb(59, 59, 59, 0.1)" : "rgb(235, 235, 235, 0.1)"};
  display: inline-block;
`
const PercentageAmount = styled.div<{ width: string, style?: CSSStyleDeclaration, dark?: boolean, color?: string }>`
  background-color: ${props => props.dark ? themeColours.ORANGE : props.color ? props.color : "rgb(235, 235, 235, 0.7)"};
  border-right: 1px solid #f2f2f2;
  font-size: 12px;
  color: #2F2F2F;
  padding: 4px;
  width: ${props => props.width};
`

const PercentageBar: FC<{ percentage: number, dark?: boolean, color?: string }> = ({ percentage, dark, color }) => (
  <PercentageWrapper dark={dark}>
    <ToolTip
      content={`${percentage}%`}
      position="bottom"
    >
      <PercentageAmount width={`${percentage}%`} dark={dark} color={color} />
    </ToolTip>
  </PercentageWrapper>
)
export default PercentageBar;