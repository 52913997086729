import { Accordion, AccordionItem, Badge, Breadcrumb, BreadcrumbItem, Panel, Tab, Tabs } from "@appkit4/react-components";
import ClientTemplate from "components/layout/clientTemplate";
import { FC, useState } from "react";
import BagEditor from "./bagEditor";
import { Bag, RuleSet, RuleStatus, Test } from "types/ruleset";
import { ClientData } from "types/analysis";
import SetEditor from "./setEditor";
import TestEditor from "./testEditor";
import { useNavigate } from "react-router-dom";
import FunctionEditor from "./functionEditor";

export enum RulePages {
  "BAGS",
  "SETS",
  "TESTS",
  "FUNCTIONS"
}

export const Statuses = [
  { label: "Draft", value: RuleStatus.Draft },
  { label: "Current", value: RuleStatus.Current },
  { label: "Disabled", value: RuleStatus.Disabled }
]

export const rulesetTableRender = (row: RuleSet | Bag | Test, field: string, clients: ClientData[]) => {
  switch (field as keyof RuleSet | Bag | Test) {
    // case "name":
    //   return row instanceof Test ? (
    //     <>
    //       <b>{row.name}</b>
    //       <p>{row.description}</p>
    //     </>
    //   );
    case "clientId":
      return (
        <>
          <span>{clients?.find(f => f.clientId === row.clientId)?.name || "Default"}</span>
        </>
      );
    case "versionStatus":
      return (<>
        <b>{RuleStatus[row.versionStatus]}</b>
        <p>{row.versionComments}</p>
      </>);
    case "versionNumber":
      return (<>
        <p>v{row.versionNumber}</p>
      </>);
  }
}

const RuleEditor: FC<{ index: RulePages }> = ({ index }) => {
  const [activeIndex, setActiveIndex] = useState<RulePages>(index);
  const [activeKeys, setActiveKeys] = useState<string[]>([]);

  const onClickAccordion = (activeKeys: string[]) => {
    setActiveKeys(activeKeys);
  }
  const navigate = useNavigate();

  const getPage = (index: RulePages) => {
    switch (index) {
      case RulePages.BAGS:
        return <BagEditor />
      case RulePages.SETS:
        return <SetEditor />
      case RulePages.TESTS:
        return <TestEditor />
      case RulePages.FUNCTIONS:
        return <FunctionEditor />
    }
  }
  const changeIndex = (index: RulePages) => {
    switch (index) {
      case RulePages.BAGS:
        setActiveIndex(RulePages.BAGS);
        navigate("/ruleset/bags");
        break;
      case RulePages.SETS:
        setActiveIndex(RulePages.SETS);
        navigate("/ruleset/sets");
        break;
      case RulePages.TESTS:
        setActiveIndex(RulePages.TESTS);
        navigate("/ruleset/tests");
        break;
      case RulePages.FUNCTIONS:
        setActiveIndex(RulePages.FUNCTIONS);
        navigate("/ruleset/functions");
        break;
    }
  }
  const getCrumb = (index: RulePages) => {
    switch (index) {
      case RulePages.BAGS:
        return "Bags";
      case RulePages.SETS:
        return "Sets";
      case RulePages.TESTS:
        return "Tests";
      case RulePages.FUNCTIONS:
        return "Functions";
    }
  }
  return (
    <ClientTemplate>
      <Accordion onClick={onClickAccordion} activeKeys={activeKeys}>
        <AccordionItem title="Editor guide" itemKey="1">
          <div style={{ marginBottom: 16 }}>
            <h3>Editing</h3>
            <p>This tool is designed to provide users with the ability to create, edit, and delete items in a hierarchical structure. The structure is organized as follows:</p>
            <ul>
              <li>Bag: The top-level container that holds a minimum of two sets.</li>
              <li>Sets: The second level container that can hold multiple tests. It is generally useful to group similar tests into sets that can then be easily mixed and matched.</li>
              <li>Tests: Can contain one or two functions. Tests with one function are considered Sensitive Access (SA) tests and those with two (or more) are considered as Segregation of Duties (SoD) tests.</li>
              <li>Functions: The most granular level, functions contain transactions and objects that are used to match authorisation data.</li>
            </ul>
            <p>With this tool, users can efficiently manage their items, ensuring a clear and organized hierarchy. The intuitive interface allows for seamless navigation and manipulation of each level, from bags down to individual functions.</p>
            <h3>Version management</h3>
            <p>Version management allows users to manage items with three distinct statuses: draft, current, and disabled. Each status dictates the actions that can be performed on the item:</p>
              <ul>
                <li>Draft: Items in draft status can be freely edited and deleted. This status is ideal for items that are still being developed or modified.</li>
                <li>Current: Items in current status are finalized and cannot be edited or deleted. To make changes to a current item, users must create a copy of the item and edit the copy.</li>
                <li>Disabled: Items in disabled status are no longer active and cannot be edited or deleted. Similar to current items, any modifications require creating a copy of the item and editing the copy.</li>
              </ul>
              <p>This ensures that finalized and inactive items remain unchanged, while still providing flexibility for ongoing development and modifications through the draft status.</p>
          </div>
        </AccordionItem>
      </Accordion>
      <Breadcrumb style={{ marginTop: 24, marginBottom: 24 }}>
        <BreadcrumbItem>Administration</BreadcrumbItem>
        <BreadcrumbItem>Rule set editor</BreadcrumbItem>
        <BreadcrumbItem>{getCrumb(index)}</BreadcrumbItem>
      </Breadcrumb>
      <Tabs activeIndex={activeIndex} onTabChange={(i) => changeIndex(i)}>
        <Tab label="Bags" value="bags"></Tab>
        <Tab label="Sets" value="sets"></Tab>
        <Tab label="Tests" value="tests"></Tab>
        <Tab label="Functions" value="functions"></Tab>
      </Tabs>
      <Panel>
        {
          getPage(activeIndex)
        }
      </Panel>
    </ClientTemplate >
  )
}

export default RuleEditor;