import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { getData } from "services/api-actions";
import { ProfileListData, RoleListData } from "types/analysis";
import { TestData } from "types/user";
import { getBasePayload } from "./analysis";
import { GeneralFilter } from "types/common";

export const FetchRoles = (analysisId?: string, filter?: GeneralFilter, offset?: number, attached?: boolean, composite?: boolean) => useQuery({
  queryKey: ["roles", analysisId, filter, offset, attached, composite],
  queryFn: () => getRoles(analysisId, filter, offset, attached, composite),
  placeholderData: keepPreviousData,
  staleTime: 1000 * 60 * 5
});

export const FetchRoleTests = (analysisId?: string, roleId?: string, filter?: GeneralFilter, composite?: boolean) => useQuery({
  queryKey: ["roleTests", analysisId, roleId, composite, filter],
  queryFn: () => getRoleTests(analysisId, roleId, filter, composite),
  staleTime: 1000 * 60 * 5
});

export const FetchProfiles = (analysisId?: string, filter?: GeneralFilter, attached?: boolean) => useQuery({
  queryKey: ["profiles", analysisId, filter, attached],
  queryFn: () => getProfiles(analysisId, filter, attached),
  staleTime: 1000 * 60 * 5
});

const getRoles = async (analysisId?: string, filter?: GeneralFilter, offset?: number, attached?: Boolean, composite?: Boolean) => {
  if (!analysisId) throw new Error("No analysis id provided");
  if (!filter) throw new Error("No filter provided");
  const payload = getBasePayload(filter, { 
    offset: offset, 
    attached: attached ? 1 : 0, 
    composite: composite ? 1 : 0
   });
  const data = await getData<RoleListData>(`analysis/${analysisId}/roles?${payload}`);
  if (!data.result) throw new Error("No data found");
  return data.result;
}

const getProfiles = async (analysisId?: string, filter?: GeneralFilter, attached?: boolean) => {
  if (!analysisId) throw new Error("No analysis id provided");
  if (!filter) throw new Error("No filter provided");
  const payload = getBasePayload(filter, { attached: attached ? 1 : 0 });
  const data = await getData<ProfileListData>(`analysis/${analysisId}/profiles?${payload}`);
  if (!data.result) throw new Error("No data found");
  return data.result;
}

const getRoleTests = async (analysisId?: string, roleId?: string, filter?: GeneralFilter, composite?: boolean) => {
  if (!analysisId) throw new Error("No analysis id provided");
  if (!roleId) throw new Error("No role id provided");
  if (!filter) throw new Error("No filter provided");
  const payload = getBasePayload(filter, { composite: composite ? 1 : 0 });
  const data = await getData<TestData[]>(`analysis/${analysisId}/roles/${roleId}/tests?${payload || ""}`);
  if (!data.result) throw new Error("No data found");
  return data.result;
}